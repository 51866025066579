import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Header.css'
import pizzalogo from "../../assets/Images/Home/PizzaLogo.png";
import { FaBars, FaTimes } from 'react-icons/fa';


const Header = () => {
  const [scroll, setScroll] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState('Home');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMenuClick = (menu) => {
    setSelectedMenu(menu);
    if (menu === 'Home') {
      navigate('/');
    } else if (menu === 'About') {
      navigate('/about');
    } else if (menu === 'Menu') {
      navigate('/menu');
    } else if (menu === 'Service') {
      navigate('/service');
    } else if (menu === 'Location') {
      navigate('/location');
    }
    if (isMobile) {
      setIsDrawerOpen(false); // Close drawer after menu item click
    }
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  

  return (
    <div>
      {!isMobile ? (
        <header className={`header ${scroll ? 'header--scrolled' : ''}`}>
          <div className="header__logo">
            <img src={pizzalogo} alt="Pizza Logo" className="logo" />
            <span className="brand-name">Pizza Da Valter</span>
          </div>
          <nav className="header__menu">
            <ul>
              {['Home', 'About', 'Menu', 'Service', 'Location'].map((menu) => (
                <li
                  key={menu}
                  className={selectedMenu === menu ? 'selected' : ''}
                  onClick={() => handleMenuClick(menu)}
                >
                  <a>{menu}</a>
                </li>
              ))}
            </ul>
          </nav>
          <div className="header__order">
            <button className="order-btn" onClick={() => navigate('../../Download')}>Order Now</button>
          
          </div>
        </header>
      ) : (
        <div className="mobile-header">
          <div className="mobile-header-left">
            <img src={pizzalogo} alt="Logo" className="mobile-header-logo" />
            <span className="mobile-header-brand-name">Pizza Da Valter</span>
          </div>
          <div className={`mobile-header-right ${isDrawerOpen ? 'mobile-header-button-move-right' : ''}`}>
            <button className="mobile-header-extra-button" onClick={() => { navigate('../../Download'); setIsDrawerOpen(false); }}>
              Order Now
            </button>
          
            <button
              className="mobile-header-drawer-toggle"
              onClick={toggleDrawer}
            >
              {isDrawerOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </button>
          </div>
          {/* Conditionally render the drawer */}
          {isDrawerOpen && (
            <div className={`mobile-header-drawer mobile-header-drawer-open`}>
              <ul>
                {['Home', 'About', 'Menu', 'Service', 'Location'].map((menu) => (
                             <li
                             key={menu}
                             className={selectedMenu === menu ? 'selected' : ''}
                             onClick={() => handleMenuClick(menu)}
                           >
                             <a>{menu}</a>
                           </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Header;
